import styled from 'styled-components'
import Img from 'gatsby-image'
import HomeFeatures from '../home-features-section/home-features-section.styles'
import { theme, media } from '../../helpers/theme'

const CallToAction = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 2fr;
  overflow: hidden;
  position: relative;
`

CallToAction.wave = styled.img`
  z-index: -1;
  position: absolute;
  grid-row-start: 2;
  grid-column-start: 1;
  max-width: 1230px;

  ${media('md')} {
    max-width: 1000px;
  }

  ${media('lg')} {
    max-width: unset;
    z-index: unset;
    position: unset;
    width: 100%;
  }
`

CallToAction.EditorImage = styled(Img)`
  width: 100%;
  height: auto;
`

CallToAction.container = styled.div`
  max-width: ${theme.breakpoints['xl']}px;
  margin: 0 auto;
  height: auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`

CallToAction.header = styled.p`
  font-size: ${theme.font['2xl']};
  text-align: center;
  margin: 0;
  font-weight: bold;

  ${media('md')} {
    font-size: ${theme.font['3xl']};
    text-align: left;
  }

  ${media('lg')} {
    font-size: ${theme.font['4xl']};
  }
`

CallToAction.text = styled.p`
  font-size: ${theme.font['md']};
  text-align: center;
  ${media('md')} {
    text-align: left;
    font-size: ${theme.font['lg']};
  }
  ${media('xl')} {
    font-size: ${theme.font['xl']};
  }
`

CallToAction.textContainer = styled.div`
  padding: 0 ${theme.space[8]}px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 1 50%;
  min-width: 300px;

  ${media('sm')} {
    min-width: 375px;
  }

  ${media('md')} {
    min-width: 450px;
  }
`

CallToAction.ImgContainer = styled.div`
  display: flex;
  height: auto;
  position: relative;
  flex: 1 1 50%;
  align-items: center;
  justify-content: center;

  min-width: 300px;

  ${media('sm')} {
    min-width: 375px;
  }

  ${media('md')} {
    min-width: 450px;
  }
`

CallToAction.buttonContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 0 ${theme.space[8]}px;
  flex-direction: column;
  align-items: center;
  grid-row-start: 2;
  grid-column-start: 1;
  margin-top: 50px;
  text-align: center;
  padding-bottom: 225px;
  color: ${theme.colors.font.white};

  ${media('md')} {
    padding-bottom: 100px;
  }

  ${media('lg')} {
    padding-bottom: 0;
    margin-bottom: 0;
    justify-content: center;
    margin-top: -10%;
  }
`

CallToAction.listBullet = styled(HomeFeatures.listBullet)`
  margin-left: 0 !important;
`

CallToAction.features = styled(HomeFeatures.features)`
  padding: 0;
  margin-bottom: ${theme.space[4]}px;
`

CallToAction.button = styled.a`
  margin-top: ${theme.space[4]}px;
  white-space: nowrap;
  text-decoration: none;
  background: #ffffff;
  color: ${theme.colors.font.black};
  background-color: ${theme.colors.accent};
  border-radius: 5px;
  padding: ${theme.space[4]}px ${theme.space[8]}px;
  font-weight: bold;

  font-size: ${theme.font['lg']};

  ${media('sm')} {
    font-size: ${theme.font['md']};
  }
`

export default CallToAction
