import React from 'react'

import { DataElementLocations } from '@eig-builder/core-utils/helpers/tagging-helper'

import JetpackHeroLayout from './jetpack-hero.styles'

import hero from './../../images/woocommerce-hero@2x.png'
import DesktopWave from './../../images/Home-header-shape.svg'
import MobileWave from './../../images/Home-header-shape-mobile.svg'
import jetpackHeader from './../../images/jetpack-header@2x.png'

const JetpackHero = () => {
  return (
    <JetpackHeroLayout>
      <JetpackHeroLayout.mobileWave src={MobileWave} />
      <JetpackHeroLayout.desktopWave src={DesktopWave} />
      <JetpackHeroLayout.content>
        <JetpackHeroLayout.contentItem>
          <JetpackHeroLayout.Logo src={jetpackHeader} alt='Jetpack Header' />
          <JetpackHeroLayout.subtitle>
            Enhancing WordPress Marketing
          </JetpackHeroLayout.subtitle>
          <JetpackHeroLayout.text>
            Creative Mail was designed to enhance your WordPress experience. Our
            work with Jetpack (and the team at WooCommerce) allows Email
            Marketing to work more easily with your WordPress site.
          </JetpackHeroLayout.text>
          <JetpackHeroLayout.desktopButton>
            <JetpackHeroLayout.button
              href={process.env.GATSBY_PLUGIN_URL}
              data-element-location={DataElementLocations.HERO}
              data-element-label='home.hero.ctaDesktop'
              data-element-id='creativemail-home-hero-cta-desktop'
            >
              Get Started
            </JetpackHeroLayout.button>
          </JetpackHeroLayout.desktopButton>
        </JetpackHeroLayout.contentItem>
        <JetpackHeroLayout.imageItem>
          <JetpackHeroLayout.image src={hero} />
          <JetpackHeroLayout.mobileButton>
            <JetpackHeroLayout.button
              href={process.env.GATSBY_PLUGIN_URL}
              data-element-location={DataElementLocations.HERO}
              data-element-label='home.hero.ctaMobile'
              data-element-id='creativemail-home-hero-cta-mobile'
            >
              Get Started
            </JetpackHeroLayout.button>
          </JetpackHeroLayout.mobileButton>
        </JetpackHeroLayout.imageItem>
      </JetpackHeroLayout.content>
    </JetpackHeroLayout>
  )
}

export default JetpackHero
