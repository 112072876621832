import React from 'react'

import Layout from './jetpack-partner-section.styles'
import JetpackForm from './../../images/jetpack-form@2x.png'

const JetpackPartnerSection = () => {
  return (
    <Layout>
      <Layout.partnership>
        {/* <Layout.Logo 
        fluid={logo.childImageSharp.fluid}
        alt='CTCT + Jetpack'
      /> */}
        <Layout.title>Jetpack + Creative Mail</Layout.title>
        <Layout.text>
          WordPress website traffic is valuable, so don't throw it away. With
          the Jetpack Form upgrade we've extended the{' '}
          <Layout.link
            href='https://jetpack.com/support/jetpack-blocks/form-block/'
            target='_blank'
          >
            Form Block
          </Layout.link>
          , so you can add a newsletter signup and opt-in functionality anywhere
          on your site in just a few clicks through the {` `}
          <Layout.link
            href='https://jetpack.com/support/jetpack-blocks/form-block/'
            target='_blank'
          >
            Form Block
          </Layout.link>
          .
        </Layout.text>
        <Layout.text>
          "The team at Constant Contact has built Creative Mail from the ground
          up to be a WordPress-first email marketing service. Creative Mail
          directly integrates with Jetpack and WooCommerce to provide you a
          seamless marketing experience."{' '}
          <Layout.text _bold>
            - Jesse Friedman, Director of Innovation for Jetpack
          </Layout.text>
        </Layout.text>
      </Layout.partnership>
      <Layout.grid>
        <Layout.content>
          <Layout.formImg src={JetpackForm} />

          <Layout.formInfo>
            <Layout.title>How The Form Works</Layout.title>
            <Layout.text>
              Add the Jetpack Newsletter Sign-up form to any post or page,
              customize it to fit your voice, and collect information vital to
              your blog or business. The form is pre-built and ready to go
              out-of-the-box so you can start collecting subscribers with just a
              few clicks. You can also modify each field, change form label
              text, and add or remove fields as needed. Set it up once and use
              the form across your site.
            </Layout.text>
          </Layout.formInfo>
        </Layout.content>
      </Layout.grid>
    </Layout>
  )
}

export default JetpackPartnerSection
