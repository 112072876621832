import React from 'react'
import Content from './jetpack-masked-content.styles'
import YellowWave from '../../images/yellow-wave.svg'
import { graphql, useStaticQuery } from 'gatsby'

const MaskedContent = () => {
  const { sync } = useStaticQuery(
    graphql`
      query {
        sync: file(relativePath: { eq: "sync@2x.png" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )

  return (
    <Content>
      <Content.Container>
        <Content.Grid>
          <Content.TextContainer>
            <Content.Text>
              <Content.Title>Automatic Form Contacts Sync</Content.Title>
              <Content.Description>
                As opted-in contacts are added via your Newsletter Sign-Up Form,
                contacts are synced automatically (for WordPress.com Business &
                Ecommerce plans) into Creative Mail. Their source is identified
                within Creative Mail and are ready for inclusion in your
                marketing campaigns.
                <br />
                <br />
                Creative Mail is still a cool option for Free, Personal or
                Premium users but they’ll need to export contacts from their
                site and then import them via CSV into Creative Mail.
              </Content.Description>
            </Content.Text>
          </Content.TextContainer>
          <Content.ImageContainer>
            <Content.Image
              fluid={sync.childImageSharp.fluid}
              alt='phone image'
              imgStyle={{ right: '0px', left: 'unset' }}
            />
          </Content.ImageContainer>
        </Content.Grid>
      </Content.Container>
      <Content.Wave src={YellowWave} alt='background wave' />
    </Content>
  )
}

export default MaskedContent
