import styled from 'styled-components'
import Img from 'gatsby-image'

import { theme, media } from '../../helpers/theme'
import { withElements } from '../../helpers/withElements'

const JetpackPartnerSection = styled.div`
  display: flex;
  flex-direction: column;
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  position: relative;
  overflow: hidden;

  width: 100%;
`

const Content = styled.div`
  max-width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap-reverse;
  max-width: ${theme.breakpoints['xl']}px;
  margin: 0 auto;

  ${media('lg')} {
    flex-direction: row;
    padding: 0 ${theme.space[8]}px;
  }
`

const FormImg = styled.img`
  min-width: 0;
  max-width: 75%;
  width: 100%;
  height: auto;

  ${media('lg')} {
    width: 50%;
    height: auto;
  }
`

const Partnership = styled.div`
  width: 100%;
  padding: 0 ${theme.space[8]}px;
  max-width: ${theme.breakpoints.md}px;
  margin: 0 auto;
  margin-bottom: ${theme.space[8]}px;

  display: flex;
  flex-direction: column;
  align-items: center;

  text-align: center;
  padding: 0 ${theme.space[4]}px;

  ${media('lg')} {
    padding: ${theme.space[8]}px;
  }
`

const FormInfo = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 0;
  flex: 1 1 50%;
  max-width: ${theme.breakpoints.md}px;
  margin: ${theme.space[8]}px auto 0 auto;

  align-items: center;
  text-align: center;
  padding: 0 ${theme.space[4]}px;

  ${media('lg')} {
    align-items: flex-start;
    text-align: start;
    padding: 0 ${theme.space[8]}px;
  }
`

const Title = styled.span`
  margin-bottom: ${theme.space[4]}px;

  font-weight: 700;
  font-size: ${theme.font['2xl']};
  ${media('md')} {
    font-size: ${theme.font['3xl']};
  }
  ${media('lg')} {
    font-size: ${theme.font['4xl']};
    margin-bottom: ${theme.space[8]}px;
  }
`

const Logo = styled(Img)`
  height: auto;
  max-width: 575px;
  width: 100%;
  margin-bottom: ${theme.space[6]}px;
`

const Text = styled.span.attrs(props => ({
  className: withElements(props)
}))`
  margin-bottom: ${theme.space[4]}px;
  font-size: ${theme.font['md']};
  ${media('md')} {
    font-size: ${theme.font['lg']};
  }
  ${media('xl')} {
    font-size: ${theme.font['xl']};
  }

  &._bold {
    font-weight: bold;
  }
`

const Link = styled.a`
  color: ${theme.colors.primary};
  cursor: pointer;
  text-decoration: unset;

  &:visited {
    color: ${theme.colors.primary};
    text-decoration: unset;
  }

  &:hover {
    text-decoration: underline;
  }
`

JetpackPartnerSection.content = Content
JetpackPartnerSection.grid = Grid
JetpackPartnerSection.formImg = FormImg
JetpackPartnerSection.partnership = Partnership
JetpackPartnerSection.formInfo = FormInfo
JetpackPartnerSection.title = Title
JetpackPartnerSection.Logo = Logo
JetpackPartnerSection.text = Text
JetpackPartnerSection.link = Link

export default JetpackPartnerSection
