import React from 'react'

import SEO from './../components/seo'
import Layout from './../components/layout'
import Footer from './../components/footer'
import JetpackHero from './../components/jetpack-hero'
import JetpackCTA from './../components/jetpack-cta'
import JetpackMaskedContent from './../components/jetpack-masked-content'
import JetpackPartnerSection from './../components/jetpack-partner-section'
import SaleModal from '../components/sale-modal'

const Home = () => {
  return (
    <Layout isWhiteLogo hideLinks>
      <SEO
        title='Email marketing with Jetpack'
        pageType='home'
        flowType='none'
      />
      <JetpackHero />
      <JetpackPartnerSection />
      <JetpackMaskedContent />
      <JetpackCTA />
      <Footer />
      <SaleModal />
    </Layout>
  )
}

export default Home
