import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { DataElementLocations } from '@eig-builder/core-utils/helpers/tagging-helper'

import CallToAction from './jetpack-cta.style'

import homeFooterWave from './../../images/homeFooterWave.svg'
import bullet1 from './../../images/bullet-1.svg'
import bullet2 from './../../images/bullet-2.svg'
import bullet3 from './../../images/bullet-3.svg'

const CTAComponent = () => {
  const { content } = useStaticQuery(
    graphql`
      query {
        content: file(relativePath: { eq: "jetpack-content@2x.png" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )

  return (
  <CallToAction>
    <CallToAction.container>
      <CallToAction.ImgContainer>
        {/* <CallToAction.person src={homeSubscribers} /> */}
        <CallToAction.EditorImage               
          fluid={content.childImageSharp.fluid}
          alt='content image'
        />
      </CallToAction.ImgContainer>
      <CallToAction.textContainer>
        <CallToAction.header>
          <span>Simplifying The Email Marketing Content Dilemma</span>
        </CallToAction.header>
        <CallToAction.features>
          <CallToAction.text>
            <span>
              One of the greatest struggles for bloggers and small business owners 
              is creating content that they can share with their audiences. Creative Mail 
              integrates tightly with your WordPress site and helps solve the content dilemma in three ways.
            </span>
          </CallToAction.text>
          <div>
            <CallToAction.listBullet first src={bullet1} />
            <span>
              First Creative Mail pulls in your website image library so that your images can be used in your email marketing as well. 
            </span>
          </div>
          <div>
            <CallToAction.listBullet src={bullet2} />
            <span>
            In addition, Creative Mail is also aware of all of your recent WordPress blog posts 
            and offers them up as featured pieces of content that can be delivered in block sections within your email marketing. 
            </span>
          </div>
          <div>
            <CallToAction.listBullet src={bullet3} />
            <span>
             Finally Creative Mail is aware of your WooCommerce store products and offers them up as promotional items to be offered as featured email content. 
            </span>
          </div>
          <CallToAction.text>
            <span>
              It’s all about delivering your message to your audience more effectively without having to create new content.
            </span>
          </CallToAction.text>
        </CallToAction.features>
        {/* <CallToAction.laptop src={laptop} /> */}
      </CallToAction.textContainer>
    </CallToAction.container>
    <CallToAction.wave src={homeFooterWave} />
    <CallToAction.buttonContainer>
      <CallToAction.header>
        <span>Ready to give it a spin?</span>
      </CallToAction.header>
      <CallToAction.text>
        <span>
          Don’t take our word for it, get the plugin to experience Creative Mail
          for yourself.
        </span>
      </CallToAction.text>
      <CallToAction.button
        data-element-location={DataElementLocations.BODY}
        data-element-label='cta.button'
        data-element-id='cta-button-get-started'
        href={process.env.GATSBY_PLUGIN_URL}
      >
        <span>GET STARTED</span>
      </CallToAction.button>
    </CallToAction.buttonContainer>
  </CallToAction>
)}

export default CTAComponent
